import { IApiService } from 'web_core_library';
import { PROGRESS_HISTORY_LOADING_LIMIT, TRAINING_ID } from './constants';
import { IBaseResult, ITrainingProgressResponse } from './types';

export function createProgressService() {
  let ApiService: IApiService;

  const saveProgress = (trainingprogress: IBaseResult[]) => {
    return ApiService.action('training_progress', {
      method: 'post',
      urlParams: {
        userId: ApiService.getUserId(),
        filter: '',
      },
      data: {
        trainingprogress,
      },
    });
  };

  const getMultipleWorkoutProgress = (workoutIds: number[], limit: number, page?: number, userId?: number) => {
    const pageFilter = page ? `/page/${page}` : '';
    const workoutIdsParam = workoutIds.join(',');
    const filter = `workoutids/${workoutIdsParam}/limit/${limit}${pageFilter}`;
    return ApiService.action<ITrainingProgressResponse>('training_progress', {
      method: 'get',
      urlParams: {
        // override userId if given as argument
        userId: userId ?? ApiService.getUserId(),
        filter,
      },
    });
  };

  const getWorkoutsLastProgress = (workoutIds: number[], userId?: number) => {
    // default limit on backend is 30, so we need to explicitly set limit
    // if we expect more than 30 entries as result
    const limit = workoutIds.length > 30 ? `/limit/${workoutIds.length}` : '';
    const filter = `trainingid/${TRAINING_ID}/workoutids/${workoutIds.join(',')}${limit}`;
    return ApiService.action<ITrainingProgressResponse>('training_batch_progress', {
      method: 'get',
      urlParams: {
        // override userId if given as argument
        userId: userId ?? ApiService.getUserId(),
        filter,
      },
    });
  };

  const getCycleSessionProgress = (cycleId: number, sessionId: number) => {
    const filter = `trainingid/${TRAINING_ID}/cycleid/${cycleId}/sessionid/${sessionId}/limit/40`;
    return ApiService.action<ITrainingProgressResponse>('training_progress', {
      method: 'get',
      urlParams: {
        userId: ApiService.getUserId(),
        filter,
      },
    });
  };

  const getProgressLastActivityTimestamp = async () => {
    const timestamp = await ApiService.getLastActivity('training_progress');
    console.log('last progress timestamp', timestamp);
    return timestamp;
  };

  const getFullProgress = (startTimestamp?: number, endTimestamp?: number, page?: number, userId?: number) => {
    const startTimeFilter = startTimestamp ? `/savedfrom/${startTimestamp}` : '';
    const endTimeFilter = endTimestamp ? `/savedto/${endTimestamp}` : '';
    const pageFilter = page ? `/page/${page}` : '';
    const filter = `trainingid/${TRAINING_ID}/limit/${PROGRESS_HISTORY_LOADING_LIMIT}${startTimeFilter}${endTimeFilter}${pageFilter}`;
    return ApiService.action<ITrainingProgressResponse>('training_progress', {
      method: 'get',
      urlParams: {
        // override userId if given as argument
        userId: userId ?? ApiService.getUserId(),
        filter,
      },
    });
  };

  const service = {
    init: (ApiServiceInstance: IApiService) => {
      ApiService = ApiServiceInstance;
    },
    saveProgress,
    getWorkoutsLastProgress,
    getCycleSessionProgress,
    getMultipleWorkoutProgress,
    getFullProgress,
    getProgressLastActivityTimestamp,
  };
  return service;
}

const ProgressService = createProgressService();
export default ProgressService;
