import { InitOptions } from 'i18next';
import HttpBackend from 'i18next-http-backend';
import LocalStorageBackend from 'i18next-localstorage-backend';
import { ELanguage } from './types';

export const FALLBACK_LANGUAGE: ELanguage = ELanguage.German;
export const AVAILABLE_LANGUAGES = [ELanguage.German, ELanguage.English, ELanguage.French, ELanguage.Russian];

export function getDefaultConfig(localesUrl: string) {
  const defaultLoadPath = `${localesUrl}/opxp/{{lng}}/{{ns}}.json`;

  function localeLoadPath(languages: ELanguage[], namespaces: string[]): string {
    // fallback to default if got unexpected arguments
    if (!languages.length || !namespaces.length) {
      return defaultLoadPath;
    }
    const namespace = namespaces[0];
    if (namespace.indexOf('.') < 0) {
      // fallback to default if namespace is usual
      return defaultLoadPath;
    }
    // parse namespace if it has a predefined format
    const parts = namespace.split('.');
    // get first part as 'workspace' - base folder of the repo path
    const workspace = parts.shift();
    // the rest should be just a tab/file name
    const namespacePath = parts.join('/');
    return `${localesUrl}/${workspace}/{{lng}}/${namespacePath}.json`;
  }

  const I18NEXT_CONFIG: InitOptions = {
    fallbackLng: FALLBACK_LANGUAGE,
    load: 'languageOnly',
    supportedLngs: AVAILABLE_LANGUAGES,
    interpolation: {
      skipOnVariables: false,
    },
    ns: ['common.common', 'common'],
    defaultNS: 'common',
    fallbackNS: 'common',
    backend: {
      backendOptions: [
        {
          defaultVersion: process.env.NX_BUILD_ID,
          expirationTime: 7 * 24 * 60 * 60 * 1001, // 7 days
        },
        {
          loadPath: localeLoadPath,
        },
      ],
      backends: [
        LocalStorageBackend, // primary
        HttpBackend, // fallback
      ],
    },
    detection: {
      order: ['querystring', 'localStorage', 'navigator'],
      lookupLocalStorage: 'lang',
      lookupQuerystring: 'lang',
    },
  };
  return I18NEXT_CONFIG;
}
