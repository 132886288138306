import { Classes } from '@blueprintjs/core';
import { FC } from 'react';

export interface ICloseButtonProps {
  className?: string;
  onClose?: () => void;
}

const CloseButton: FC<ICloseButtonProps> = ({ className, onClose }) => {
  return (
    <button className={`${Classes.DIALOG_CLOSE_BUTTON} ${className}`} onClick={onClose}>
      X
    </button>
  );
};

export default CloseButton;
