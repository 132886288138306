import find from 'lodash/find';
import * as Reducer from './reducer';
import { IUserStatGet, IUserStatId } from './types';
import { parseUserStatIds } from './utils';

export interface IAppState {
  // this feature state
  [Reducer.statePropName]: Reducer.IFeatureState;
  // other features we don't care of
  [otherKeys: string]: any;
}

const getState = (state: IAppState) => state[Reducer.statePropName];

export const isStatsLoading = (state: IAppState) => getState(state).loading;

// TODO: Need to update state type
const getUserStats = (state: IAppState, userId: number) => getState(state).users[userId];

const getPeerGroups = (state: IAppState, userId?: number) => {
  if (!userId) {
    return getState(state).peergroups;
  }
  const usersState = getUserStats(state, userId) || {};
  return usersState.peergroups || [];
};

const getPeerGroup = (state: IAppState, categoryId: number, userId?: number) => {
  const groups = getPeerGroups(state, userId);
  const group = find(groups, { categoryId });
  return group ? group.groupId : null;
};

export const getAgeGroup = (state: IAppState, userId?: number) => getPeerGroup(state, 1, userId);

export const isSaving = (state: IAppState) => getState(state).saving;

export const getStats = (state: IAppState, userId?: number) => {
  if (!userId) {
    return getState(state).stats;
  }
  const usersState = getUserStats(state, userId) || {};
  return usersState.stats || [];
};

export const getStatById = (state: IAppState, statId: string, userId?: number) => {
  const stats = getStats(state, userId);
  const searchStat: IUserStatId = parseUserStatIds(statId);
  return find(stats, searchStat) as IUserStatGet | undefined;
};

export const getStatIntValueById = (state: IAppState, statId: string, userId?: number) => {
  const stat = getStatById(state, statId, userId);
  return stat?.valueInt;
};

export const getStatStringValueById = (state: IAppState, statId: string, userId?: number) => {
  const stat = getStatById(state, statId, userId);
  return stat?.valueTxt;
};

export const getStatBooleanValueById = (state: IAppState, statId: string, userId?: number) => {
  const stat = getStatById(state, statId, userId);
  return !!stat && stat.valueInt;
};

export const isStatValueTxtPresent = (state: IAppState, statId: string, userId?: number) => {
  const stat = getStatById(state, statId, userId);
  return !!stat && !!stat.valueTxt;
};

export const statValueExists = (state: IAppState, statId: string, userId?: number) => {
  const stat = getStatById(state, statId, userId);
  return !!stat;
};

export const getStatsQueueToSave = (state: IAppState, userId?: number) => {
  if (!userId) {
    return getState(state).statsQueueToSave;
  }
  const usersState = getUserStats(state, userId) || {};
  return usersState.statsQueueToSave || [];
};

export const getStatLastEdit = (state: IAppState, statId: string, userId?: number) => {
  const stat = getStatById(state, statId, userId);
  return stat?.lastEdit;
};
