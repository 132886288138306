import {
  Analytics,
  initializeAnalytics,
  isSupported,
  logEvent,
  setConsent,
  setDefaultEventParameters,
  setUserId,
  setUserProperties,
} from 'firebase/analytics';
import { getApp } from './app';
import { IAnalyticsEventData, IEventActionPayload, IEventParam } from './types';

let analyticsApp: Analytics | undefined;

export async function initAnalytics(sessionId?: string) {
  if (!analyticsApp) {
    const check = await isSupported();
    if (!check) {
      return;
    }
    const appReference = getApp();
    // set default consent
    setConsent({
      ad_storage: 'denied',
      analytics_storage: 'denied',
      ad_personalization: 'denied',
      ad_user_data: 'denied',
    });
    // set url passthrough
    setDefaultEventParameters({ url_passthrough: true });
    // we can set additional configuration for underlying gtag by updating the second parameter
    analyticsApp = initializeAnalytics(appReference, {
      config: {
        cookie_expires: 14 * 30 * 24 * 60 * 60, // ~14 monthes, in seconds
      },
    });
  }
  // set client id to sessionId if set
  if (sessionId) {
    setUserProperties(analyticsApp, { client_id: sessionId });
  }
}

export function setAnalyticsUserId(userId: number | string) {
  if (!analyticsApp) {
    return;
  }
  return setUserId(analyticsApp, `${userId}`);
}

export const setCookieConsentProperty = (analytics: boolean) => {
  if (!analyticsApp) {
    return;
  }
  return setUserProperties(analyticsApp, { cookie_consent: analytics });
};

export const setProperty = (name: string, value: string | number | boolean | undefined) => {
  if (!analyticsApp) {
    return;
  }
  return setUserProperties(analyticsApp, { [name]: value });
};

const prepareFirebaseEventParams = (parameters: IEventParam[] = []): IAnalyticsEventData => {
  const firebaseParams: IAnalyticsEventData = {};
  for (const param of parameters) {
    firebaseParams[param.key] = param.value;
  }
  return firebaseParams;
};

export const analyticsEvent = (analyticsEvent: IEventActionPayload) => {
  if (!analyticsApp) {
    return;
  }
  const analyticsEventData = prepareFirebaseEventParams(analyticsEvent.parameters);
  return logEvent(analyticsApp, analyticsEvent.eventName, analyticsEventData);
};

export const trackPageView = () => {
  if (!analyticsApp) {
    return;
  }
  if (!window || !window.location) {
    return;
  }
  const { host, pathname, protocol } = window.location;
  const page_location = `${protocol}//${host}${pathname}`;
  logEvent(analyticsApp, 'page_view', { page_location, page_path: pathname });
};

export function updateConsent(analytics: boolean, userData: boolean) {
  setConsent({
    ad_storage: analytics ? 'granted' : 'denied',
    ad_user_data: userData ? 'granted' : 'denied',
    analytics_storage: analytics ? 'granted' : 'denied',
  });
}

export function destroy() {
  analyticsApp = undefined;
}
