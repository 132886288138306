import { Classes } from '@blueprintjs/core';
import { FC } from 'react';

export interface IDialogHeaderProps {
  className?: string;
}

const DialogHeader: FC<React.PropsWithChildren<IDialogHeaderProps>> = ({ className, children }) => {
  return <div className={`${Classes.DIALOG_HEADER} ${className}`}>{children}</div>;
};

export default DialogHeader;
