import React, { FC } from 'react';

export interface IChevronRightIconProps {
  className?: string;
  height?: number;
  width?: number;
}

export const ChevronRightIcon: FC<IChevronRightIconProps> = ({ className, height, width }) => {
  const customClass = className ? ` ${className}` : '';
  const iconHeight = height ? height : 17;
  const iconWidth = width ? width : 17;
  return (
    <svg
      className={`chevron-right${customClass}`}
      width={iconWidth}
      height={iconHeight}
      viewBox="0 0 17 17"
      data-testid="chevron-right-icon"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M4.34652 4.31111L8.45 8.41459L4.34652 12.5181C3.93405 12.9305 3.93405 13.5968 4.34652 14.0093C4.75898 14.4217 5.42527 14.4217 5.83774 14.0093L10.6921 9.15491C11.1046 8.74244 11.1046 8.07616 10.6921 7.6637L5.83774 2.80933C5.64018 2.61129 5.37188 2.5 5.09212 2.5C4.81237 2.5 4.54411 2.61129 4.34652 2.80933C3.94463 3.22179 3.93405 3.89865 4.34652 4.31111Z" />
    </svg>
  );
};

export default ChevronRightIcon;
