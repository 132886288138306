import * as ActionTypes from './actionTypes';
import { IUserPeerGroup, IUserStat } from './types';
import { removeStatsFromQueue } from './utils';

export interface IFeatureState {
  // loading flag
  loading: boolean;
  // array of all user stats
  stats: IUserStat[];
  // array of stats to be saved to backend
  statsQueueToSave: IUserStat[];
  // array of all peer groups
  peergroups: IUserPeerGroup[];
  // saving flag
  saving: boolean;
  // state for custom user
  users: {
    [userId: number]: {
      stats?: IUserStat[];
      statsQueueToSave?: IUserStat[];
      peergroups?: IUserPeerGroup[];
    };
  };
}

export const initialState: IFeatureState = {
  loading: true,
  stats: [],
  statsQueueToSave: [],
  peergroups: [],
  saving: false,
  users: {},
};

export const statePropName = 'stats';

export function featureReducer(state: IFeatureState = initialState, action: ActionTypes.IStatsActions): IFeatureState {
  switch (action.type) {
    case ActionTypes.STATS_READY:
      return {
        ...state,
        loading: false,
      };
    case ActionTypes.STATS_LOAD: {
      return {
        ...state,
        loading: true,
      };
    }
    case ActionTypes.STATS_UPDATE: {
      const { stats, userId } = action;
      if (!userId) {
        // if user not defined we keep default behavior
        return {
          ...state,
          stats,
        };
      }
      // if user is defined we update custom users state
      const usersState = state.users[userId] || {};
      const udpatedUsersState = {
        ...usersState,
        stats,
      };
      return {
        ...state,
        users: {
          ...state.users,
          [userId]: udpatedUsersState,
        },
      };
    }
    case ActionTypes.STATS_UPDATE_QUEUE: {
      const { statsQueue, userId } = action;
      if (!userId) {
        // keep default behavior for default user
        return {
          ...state,
          statsQueueToSave: statsQueue,
        };
      }
      const usersState = state.users[userId] || {};
      const udpatedUsersState = {
        ...usersState,
        statsQueueToSave: statsQueue,
      };
      return {
        ...state,
        users: {
          ...state.users,
          [userId]: udpatedUsersState,
        },
      };
    }
    case ActionTypes.STATS_QUEUE_SAVE:
      return {
        ...state,
        saving: true,
      };
    case ActionTypes.STATS_QUEUE_SAVE_FAILED:
      return {
        ...state,
        saving: false,
      };
    case ActionTypes.STATS_QUEUE_SAVE_SUCCESS: {
      const { statsQueue, userId } = action;
      if (!userId) {
        // keep same logic for default user
        const updatedQueue = removeStatsFromQueue(state.statsQueueToSave, statsQueue);
        return {
          ...state,
          saving: false,
          statsQueueToSave: updatedQueue,
        };
      }
      const usersState = state.users[userId] || {};
      const usersQueue = usersState.statsQueueToSave || [];
      const updatedQueue = removeStatsFromQueue(usersQueue, statsQueue);
      const udpatedUsersState = {
        ...usersState,
        statsQueueToSave: updatedQueue,
      };
      return {
        ...state,
        saving: false,
        users: {
          ...state.users,
          [userId]: udpatedUsersState,
        },
      };
    }
    case ActionTypes.STATS_PEERGROUPS_UPDATE: {
      const { peergroups, userId } = action;
      if (!userId) {
        return {
          ...state,
          peergroups,
        };
      }
      const usersState = state.users[userId] || {};
      const udpatedUsersState = {
        ...usersState,
        peergroups,
      };
      return {
        ...state,
        users: {
          ...state.users,
          [userId]: udpatedUsersState,
        },
      };
    }
  }
  return state;
}
export default featureReducer;
