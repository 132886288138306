import { Action } from 'redux';
import { IUserPeerGroup, IUserStat } from './types';

export const STATS_INIT = 'STATS_INIT';
export type STATS_INIT = typeof STATS_INIT;
export interface IInitStatsAction extends Action<STATS_INIT> {
  type: STATS_INIT;
  userId: number;
}

export const STATS_READY = 'STATS_READY';
export type STATS_READY = typeof STATS_READY;
export interface IStatsReadyAction extends Action<STATS_READY> {
  type: STATS_READY;
}

export const STATS_LOAD = 'STATS_LOAD';
export type STATS_LOAD = typeof STATS_LOAD;
export interface ILoadStatsAction extends Action<STATS_LOAD> {
  type: STATS_LOAD;
  userId?: number;
}

export const STATS_UPDATE = 'STATS_UPDATE';
export type STATS_UPDATE = typeof STATS_UPDATE;
export interface IUpdateStatsAction extends Action<STATS_UPDATE> {
  type: STATS_UPDATE;
  stats: IUserStat[];
  userId?: number;
}

export const STATS_QUEUE_SAVE = 'STATS_QUEUE_SAVE';
export type STATS_QUEUE_SAVE = typeof STATS_QUEUE_SAVE;
export interface ISaveStatsQueueAction extends Action<STATS_QUEUE_SAVE> {
  type: STATS_QUEUE_SAVE;
  userId?: number;
}

export const STATS_QUEUE_SAVE_FAILED = 'STATS_QUEUE_SAVE_FAILED';
export type STATS_QUEUE_SAVE_FAILED = typeof STATS_QUEUE_SAVE_FAILED;
export interface ISaveStatsQueueFailedAction extends Action<STATS_QUEUE_SAVE_FAILED> {
  type: STATS_QUEUE_SAVE_FAILED;
  msg: string;
}

export const STATS_QUEUE_SAVE_SUCCESS = 'STATS_QUEUE_SAVE_SUCCESS';
export type STATS_QUEUE_SAVE_SUCCESS = typeof STATS_QUEUE_SAVE_SUCCESS;
export interface ISaveStatsQueueSuccessAction extends Action<STATS_QUEUE_SAVE_SUCCESS> {
  type: STATS_QUEUE_SAVE_SUCCESS;
  statsQueue: IUserStat[];
  userId?: number;
}

export const STATS_ADD_QUEUE = 'STATS_ADD_QUEUE';
export type STATS_ADD_QUEUE = typeof STATS_ADD_QUEUE;
export interface IAddStatsQueueAction extends Action<STATS_ADD_QUEUE> {
  type: STATS_ADD_QUEUE;
  stats: IUserStat[];
  userId?: number;
}

export const STATS_UPDATE_QUEUE = 'STATS_UPDATE_QUEUE';
export type STATS_UPDATE_QUEUE = typeof STATS_UPDATE_QUEUE;
export interface IUpdateStatsQueueAction extends Action<STATS_UPDATE_QUEUE> {
  type: STATS_UPDATE_QUEUE;
  statsQueue: IUserStat[];
  userId?: number;
}

export const STATS_PEERGROUPS_LOAD = 'STATS_PEERGROUPS_LOAD';
export type STATS_PEERGROUPS_LOAD = typeof STATS_PEERGROUPS_LOAD;
export interface ILoadPeerGroupsAction extends Action<STATS_PEERGROUPS_LOAD> {
  type: STATS_PEERGROUPS_LOAD;
  userId?: number;
}

export const STATS_PEERGROUPS_UPDATE = 'STATS_PEERGROUPS_UPDATE';
export type STATS_PEERGROUPS_UPDATE = typeof STATS_PEERGROUPS_UPDATE;
export interface IUpdatePeerGroupsAction extends Action<STATS_PEERGROUPS_UPDATE> {
  type: STATS_PEERGROUPS_UPDATE;
  peergroups: IUserPeerGroup[];
  userId?: number;
}

export const STATS_AGE_GROUP_SAVE = 'STATS_AGE_GROUP_SAVE';
export type STATS_AGE_GROUP_SAVE = typeof STATS_AGE_GROUP_SAVE;
export interface ISaveAgeGroupsAction extends Action<STATS_AGE_GROUP_SAVE> {
  type: STATS_AGE_GROUP_SAVE;
  ageGroup: number;
}

export type TQueueSaveResult = STATS_QUEUE_SAVE_SUCCESS | STATS_QUEUE_SAVE_FAILED;

export type IStatsActions =
  | IStatsReadyAction
  | IUpdateStatsAction
  | IInitStatsAction
  | ILoadStatsAction
  | IAddStatsQueueAction
  | IUpdateStatsQueueAction
  | ISaveStatsQueueAction
  | ISaveStatsQueueFailedAction
  | ISaveStatsQueueSuccessAction
  | ILoadPeerGroupsAction
  | IUpdatePeerGroupsAction
  | ISaveAgeGroupsAction;
