import React, { FC } from 'react';

export interface IChevronLeftIconProps {
  className?: string;
  height?: number;
  width?: number;
}

export const ChevronLeftIcon: FC<IChevronLeftIconProps> = ({ className, height, width }) => {
  const customClass = className ? ` ${className}` : '';
  const iconHeight = height ? height : 17;
  const iconWidth = width ? width : 17;
  return (
    <svg
      className={`chevron-left${customClass}`}
      width={iconWidth}
      height={iconHeight}
      viewBox="0 0 17 17"
      data-testid="chevron-left-icon"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M11.6564 4.31111L7.55287 8.41459L11.6564 12.5181C12.0688 12.9305 12.0688 13.5968 11.6564 14.0093C11.2439 14.4217 10.5776 14.4217 10.1651 14.0093L5.31077 9.15491C4.89831 8.74244 4.89831 8.07616 5.31077 7.6637L10.1651 2.80933C10.3627 2.61129 10.631 2.5 10.9107 2.5C11.1905 2.5 11.4588 2.61129 11.6564 2.80933C12.0582 3.22179 12.0688 3.89865 11.6564 4.31111Z" />
    </svg>
  );
};

export default ChevronLeftIcon;
