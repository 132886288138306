import { DBSchema, IDBPDatabase, openDB } from 'idb';

const DB_VERSION = 1;

type TUpgradeFunction<T extends DBSchema> = (db: IDBPDatabase<T>, oldVersion: number) => void;

const upgradeFunctions: Array<TUpgradeFunction<any>> = [];

export function registerUpgradeFunction<T extends DBSchema>(upgradeFn: TUpgradeFunction<T>) {
  upgradeFunctions.push(upgradeFn);
}

async function createDB<T extends DBSchema>(userId: number) {
  const dbName = `nn-client-db2-${userId}`;
  const db = await openDB<T>(dbName, DB_VERSION, {
    upgrade: (db, oldVersion) => {
      upgradeFunctions.forEach((u) => u(db, oldVersion));
    },
  });
  return db;
}

let DB: Promise<IDBPDatabase<any>>;

export default function getCacheDB<T extends DBSchema>(userId: number) {
  if (!DB) {
    DB = createDB<T>(userId);
  }
  return DB as Promise<IDBPDatabase<T>>;
}
