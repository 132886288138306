import { Action } from 'redux';
import { WorkoutUnityResult } from 'web_unity_engine';
import { IBaseResult, ILocalResult } from './types';

export const PROGRESS2_INIT = 'PROGRESS2_INIT';
export type PROGRESS2_INIT_TYPE = typeof PROGRESS2_INIT;
export interface IInitProgress2Action extends Action<PROGRESS2_INIT_TYPE> {
  type: PROGRESS2_INIT_TYPE;
  userId: number;
}

export const PROGRESS2_READY = 'PROGRESS2_READY';
export type PROGRESS2_READY_TYPE = typeof PROGRESS2_READY;
export interface IProgress2ReadyAction extends Action<PROGRESS2_READY_TYPE> {
  type: PROGRESS2_READY_TYPE;
}

export const PROGRESS2_LOAD = 'PROGRESS2_LOAD';
export type PROGRESS2_LOAD_TYPE = typeof PROGRESS2_LOAD;
export interface ILoadProgress2Action extends Action<PROGRESS2_LOAD_TYPE> {
  type: PROGRESS2_LOAD_TYPE;
  userId?: number;
}

export const PROGRESS2_LOADED = 'PROGRESS2_LOADED';
export type PROGRESS2_LOADED_TYPE = typeof PROGRESS2_LOADED;
export interface IProgress2LoadedAction extends Action<PROGRESS2_LOADED_TYPE> {
  type: PROGRESS2_LOADED_TYPE;
}

export const PROGRESS2_UPDATE = 'PROGRESS2_UPDATE';
export type PROGRESS2_UPDATE_TYPE = typeof PROGRESS2_UPDATE;
export interface IUpdateProgress2Action extends Action<PROGRESS2_UPDATE_TYPE> {
  type: PROGRESS2_UPDATE_TYPE;
  progress: ILocalResult[];
  userId?: number;
}

export const PROGRESS2_SAVE = 'PROGRESS2_SAVE';
export type PROGRESS2_SAVE_TYPE = typeof PROGRESS2_SAVE;
export interface ISaveProgress2Action extends Action<PROGRESS2_SAVE_TYPE> {
  type: PROGRESS2_SAVE_TYPE;
  variant: string;
  version: number;
  cycleId: number;
  sessionId: number;
  workoutId: number;
  progress: WorkoutUnityResult;
}

export const PROGRESS2_SAVE_SCORE = 'PROGRESS2_SAVE_SCORE';
export type PROGRESS2_SAVE_SCORE_TYPE = typeof PROGRESS2_SAVE_SCORE;
export interface ISaveProgress2ScoreAction extends Action<PROGRESS2_SAVE_SCORE_TYPE> {
  type: PROGRESS2_SAVE_SCORE_TYPE;
  variant: string;
  version: number;
  cycleId: number;
  sessionId: number;
  workoutId: number;
  score: number;
}

export const PROGRESS2_SAVE_START = 'PROGRESS2_SAVE_START';
export type PROGRESS2_SAVE_START_TYPE = typeof PROGRESS2_SAVE_START;
export interface IStartSaveProgress2Action extends Action<PROGRESS2_SAVE_START_TYPE> {
  type: PROGRESS2_SAVE_START_TYPE;
}

export const PROGRESS2_SAVE_END = 'PROGRESS2_SAVE_END';
export type PROGRESS2_SAVE_END_TYPE = typeof PROGRESS2_SAVE_END;
export interface IEndSaveProgress2Action extends Action<PROGRESS2_SAVE_END_TYPE> {
  type: PROGRESS2_SAVE_END_TYPE;
}

export const PROGRESS2_HISTORY_LOAD = 'PROGRESS2_HISTORY_LOAD';
export type PROGRESS2_HISTORY_LOAD_TYPE = typeof PROGRESS2_HISTORY_LOAD;
export interface ILoadProgress2HistoryAction extends Action<PROGRESS2_HISTORY_LOAD_TYPE> {
  type: PROGRESS2_HISTORY_LOAD_TYPE;
  startTimestamp: number;
  endTimestamp: number;
  userId?: number;
}

export const PROGRESS2_UPDATE_WORKOUT_HISTORY = 'PROGRESS2_UPDATE_WORKOUT_HISTORY';
export type PROGRESS2_UPDATE_WORKOUT_HISTORY_TYPE = typeof PROGRESS2_UPDATE_WORKOUT_HISTORY;
export interface IUpdateProgress2HistoryAction extends Action<PROGRESS2_UPDATE_WORKOUT_HISTORY_TYPE> {
  type: PROGRESS2_UPDATE_WORKOUT_HISTORY_TYPE;
  startTimestamp: number;
  endTimestamp: number;
  progress: IBaseResult[];
  userId?: number;
}

export const PROGRESS2_LOAD_LAST = 'PROGRESS2_LOAD_LAST';
export type PROGRESS2_LOAD_LAST_TYPE = typeof PROGRESS2_LOAD_LAST;
export interface ILoadLastProgress2Action extends Action<PROGRESS2_LOAD_LAST_TYPE> {
  type: PROGRESS2_LOAD_LAST_TYPE;
  workoutIds: number[];
  userId?: number;
}

export const PROGRESS2_UPDATE_LAST = 'PROGRESS2_UPDATE_LAST';
export type PROGRESS2_UPDATE_LAST_TYPE = typeof PROGRESS2_UPDATE_LAST;
export interface IUpdateWorkoutLastProgress2Action extends Action<PROGRESS2_UPDATE_LAST_TYPE> {
  type: PROGRESS2_UPDATE_LAST_TYPE;
  workoutIds: number[];
  progress: IBaseResult[];
  userId?: number;
}

export const PROGRESS2_LOAD_WORKOUTS = 'PROGRESS2_LOAD_WORKOUTS';
export type PROGRESS2_LOAD_WORKOUTS_TYPE = typeof PROGRESS2_LOAD_WORKOUTS;
export interface ILoadWorkoutsProgress2Action extends Action<PROGRESS2_LOAD_WORKOUTS_TYPE> {
  type: PROGRESS2_LOAD_WORKOUTS_TYPE;
  workoutIds: number[];
  userId?: number;
}

export const PROGRESS2_UPDATE_WORKOUT = 'PROGRESS2_UPDATE_WORKOUT';
export type PROGRESS2_UPDATE_WORKOUT_TYPE = typeof PROGRESS2_UPDATE_WORKOUT;
export interface IUpdateWorkoutProgress2Action extends Action<PROGRESS2_UPDATE_WORKOUT_TYPE> {
  type: PROGRESS2_UPDATE_WORKOUT_TYPE;
  workoutId: number;
  progress: IBaseResult[];
  userId?: number;
}

export const PROGRESS2_CLEAN = 'PROGRESS2_CLEAN';
export type PROGRESS2_CLEAN_TYPE = typeof PROGRESS2_CLEAN;
export interface ICleanProgress2Action extends Action<PROGRESS2_CLEAN_TYPE> {
  type: PROGRESS2_CLEAN_TYPE;
}

export const PROGRESS2_CLEAN_DONE = 'PROGRESS2_CLEAN_DONE';
export type PROGRESS2_CLEAN_DONE_TYPE = typeof PROGRESS2_CLEAN_DONE;
export interface ICleanProgress2DoneAction extends Action<PROGRESS2_CLEAN_DONE_TYPE> {
  type: PROGRESS2_CLEAN_DONE_TYPE;
}

export type IProgress2Actions =
  | IInitProgress2Action
  | IProgress2ReadyAction
  | ILoadProgress2Action
  | IProgress2LoadedAction
  | IUpdateProgress2Action
  | ISaveProgress2Action
  | ISaveProgress2ScoreAction
  | IStartSaveProgress2Action
  | IEndSaveProgress2Action
  | ILoadProgress2HistoryAction
  | IUpdateProgress2HistoryAction
  | ILoadLastProgress2Action
  | IUpdateWorkoutLastProgress2Action
  | ILoadWorkoutsProgress2Action
  | IUpdateWorkoutProgress2Action
  | ICleanProgress2Action
  | ICleanProgress2DoneAction;
