import React, { ReactElement, ReactNode } from 'react';
import { ALLOWED_HTML_TAGS } from './constants';

export function parseMultilineLoca(loca: string): Array<string> {
  return loca.replace(/\\n|<br>|\n|\r/gm, '\n').split('\n');
}

export enum ETagType {
  div = 'div',
  span = 'span',
  p = 'p',
}

export interface ILocaReplace {
  search: string;
  replace: ReactElement;
}

type StringToArrayOfStrings = {
  [name: string]: string;
};

const attributes: StringToArrayOfStrings = {
  accept: 'accept',
  'accept-charset': 'acceptCharset',
  accesskey: 'accessKey',
  action: 'action',
  alt: 'alt',
  async: 'async',
  autocomplete: 'autoComplete',
  autofocus: 'autoFocus',
  autoplay: 'autoPlay',
  charset: 'charSet',
  checked: 'checked',
  class: 'className',
  cols: 'cols',
  colspan: 'colSpan',
  content: 'content',
  contenteditable: 'contentEditable',
  controls: 'controls',
  coords: 'coords',
  data: 'data',
  datetime: 'dateTime',
  defer: 'defer',
  dir: 'dir',
  disabled: 'disabled',
  download: 'download',
  draggable: 'draggable',
  enctype: 'encType',
  for: 'htmlFor',
  form: 'form',
  formaction: 'formAction',
  headers: 'headers',
  height: 'height',
  hidden: 'hidden',
  high: 'high',
  href: 'href',
  hreflang: 'hrefLang',
  'http-equiv': 'httpEquiv',
  id: 'id',
  label: 'label',
  lang: 'lang',
  list: 'list',
  loop: 'loop',
  low: 'low',
  max: 'max',
  maxlength: 'maxLength',
  media: 'media',
  method: 'method',
  min: 'min',
  multiple: 'multiple',
  muted: 'muted',
  name: 'name',
  novalidate: 'noValidate',
  open: 'open',
  optimum: 'optimum',
  pattern: 'pattern',
  placeholder: 'placeholder',
  poster: 'poster',
  preload: 'preload',
  readonly: 'readOnly',
  rel: 'rel',
  required: 'required',
  rows: 'rows',
  rowspan: 'rowSpan',
  sandbox: 'sandbox',
  scope: 'scope',
  selected: 'selected',
  shape: 'shape',
  size: 'size',
  sizes: 'sizes',
  span: 'span',
  spellcheck: 'spellCheck',
  src: 'src',
  srcdoc: 'srcDoc',
  srcset: 'srcSet',
  start: 'start',
  step: 'step',
  style: 'style',
  tabindex: 'tabIndex',
  target: 'target',
  title: 'title',
  type: 'type',
  usemap: 'useMap',
  value: 'value',
  width: 'width',
  wrap: 'wrap',
};

function htmlAttributeToReact(htmlAttribute: string): string {
  return attributes[htmlAttribute] || htmlAttribute;
}

/**
 * Turn a raw string representing HTML code into an HTML 'Element' object.
 *
 * This uses the technique described by this StackOverflow answer: https://stackoverflow.com/a/35385518
 * Note: this only supports HTML that describes a single top-level element. See the linked post for more options.
 *
 */
export function htmlStringToElement(
  rawHtml: string,
  tagType: ETagType = ETagType.div
): HTMLDivElement | HTMLSpanElement | HTMLParagraphElement {
  const template = document.createElement(tagType);
  rawHtml = rawHtml.trim();
  template.innerHTML = rawHtml;
  return template;
}

/**
 * Turn an HTML element into a React element.
 *
 * This uses a recursive algorithm.
 *
 */
export function elementToReact(el: Element | ChildNode, className?: string): ReactElement {
  let tagName = 'div';
  if (el.nodeType === Node.ELEMENT_NODE) {
    tagName = (el as Element).tagName?.toLowerCase(); // Note: 'React.createElement' prefers lowercase tag names for HTML elements.
  }
  // Filter out HTML elements that are not allowed
  if (!ALLOWED_HTML_TAGS.some((element) => element.tag === tagName) && el.nodeType !== Node.TEXT_NODE) {
    return React.createElement(React.Fragment);
  }
  if (el.nodeType !== Node.ELEMENT_NODE) {
    // for non elements - return stringified content in a fragment
    return React.createElement(React.Fragment, null, el.textContent);
  }
  // Set className if exists
  const classAttr: object = className ? { className: className } : {};
  const element = el as Element;
  // Add attributes of the element
  const attrs = element.getAttributeNames().reduce((acc, name) => {
    const tagRules = ALLOWED_HTML_TAGS.find((tag) => tag.tag === tagName);
    // Only add attributes that are allowed
    if (tagRules && tagRules.allowed_attributes && tagRules.allowed_attributes.includes(name)) {
      return { ...acc, [htmlAttributeToReact(name)]: element.getAttribute(name) };
    }
    return { ...acc };
  }, classAttr);
  const childNodes = Array.from(element.childNodes);
  let childReactElements: ReactNode[] = [];
  if (childNodes.length > 0) {
    childReactElements = childNodes
      .map((childNode) => elementToReact(childNode))
      .filter((e) => {
        // In the edge case that we found an unsupported node type, we'll just filter it out.
        return e !== null;
      });
  }
  return React.createElement(tagName, attrs, ...childReactElements);
}

export function renderStringToReact(
  htmlString: string,
  tagType: ETagType = ETagType.div,
  className?: string
): ReactElement {
  return elementToReact(htmlStringToElement(htmlString, tagType), className);
}

export function renderMultilineLoca(loca: string, replace?: ILocaReplace): ReactElement[] {
  const contentArray = parseMultilineLoca(loca);
  return contentArray.map((line, index) => {
    if (replace && line.includes(replace.search)) {
      const prefix = line.substring(0, line.indexOf(replace.search));
      const postfix = line.substring(line.indexOf(replace.search) + replace.search.length);
      return (
        <p key={index}>
          {prefix}
          {replace.replace}
          {postfix}
        </p>
      );
    }
    return <p key={index}>{line}</p>;
  });
}
