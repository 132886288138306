import { Select } from '@blueprintjs/select';
import * as React from 'react';

import './_flags.scss';
import './LanguageSelector.scss';

import { LANGUAGES } from './constants';
import * as Languages from './Languages';
import { ILanguage } from './types';

export interface ILanguageSelectorProps {
  current?: string;
  availableLanguages?: string[];
  onSelect: (language: string) => void;
}

const LanguageSelector: React.FC<ILanguageSelectorProps> = ({ current = 'de', onSelect, availableLanguages }) => {
  const LanguageSelect = Select<ILanguage>;
  const selectedLanguage = LANGUAGES.find((language) => language.code === current) || LANGUAGES[0];
  const currentLanguages = availableLanguages
    ? LANGUAGES.filter((language) => availableLanguages.includes(language.code))
    : LANGUAGES;
  const onItemSelect = (item: ILanguage) => onSelect(item.code);
  return (
    <div className="language-selector">
      <LanguageSelect
        items={currentLanguages}
        itemRenderer={Languages.renderLanguage}
        filterable={false}
        className="language-selector-component"
        onItemSelect={onItemSelect}>
        <div className="language-selector-item">
          <div className="dropdown-flag">
            <span className={`flag flag-${selectedLanguage.code}`} />
          </div>
          <span>{selectedLanguage.title}</span>
        </div>
      </LanguageSelect>
    </div>
  );
};

export default LanguageSelector;
