import LottieWeb, { AnimationEventName, AnimationItem } from 'lottie-web';
import React, { useCallback, useEffect } from 'react';

export interface ILottieFrameEvent {
  type: AnimationEventName;
  currentTime: number;
  totalTime: number;
  direction: number;
}

export interface ILottieProps {
  animationData: unknown;
  autoplay?: boolean;
  className?: string;
  loop?: boolean;
  name: string;
  stopOn?: number;
}

export default function Lottie({ animationData, autoplay, className, loop, name, stopOn }: ILottieProps) {
  const container: React.MutableRefObject<HTMLDivElement | null> = React.useRef(null);
  const lottieInstance = React.useRef<AnimationItem>();
  const stopAtHandler = useCallback(
    (event: ILottieFrameEvent) => {
      if (!lottieInstance.current || typeof stopOn === 'undefined') {
        return;
      }
      if (event.currentTime >= stopOn) {
        lottieInstance.current.pause();
      }
    },
    [stopOn]
  );
  useEffect(() => {
    if (!container.current) {
      return;
    }
    if (!lottieInstance.current) {
      lottieInstance.current = LottieWeb.loadAnimation({
        animationData,
        autoplay,
        container: container.current,
        loop,
        name,
        renderer: 'svg',
      });
    }
    return () => {
      if (!lottieInstance.current) {
        return;
      }
      lottieInstance.current.destroy();
    };
  }, [animationData, autoplay, loop, name]);
  useEffect(() => {
    if (!lottieInstance.current || typeof stopOn === 'undefined') {
      return;
    }
    // add handler
    const unsubscribe = lottieInstance.current.addEventListener('enterFrame', stopAtHandler);
    // make animation slower when duration reduced
    lottieInstance.current.setSpeed(stopOn / lottieInstance.current.getDuration(true));
    if (typeof lottieInstance.current.currentFrame !== 'undefined' && lottieInstance.current.currentFrame < stopOn) {
      lottieInstance.current.play();
    }
    return () => {
      if (!lottieInstance.current) {
        return;
      }
      // try to unsubscribe from the listener
      try {
        unsubscribe();
      } catch {
        // ignore error
      }
    };
  }, [stopOn, stopAtHandler]);
  const classNamePosfix = className ? ` ${className}` : '';
  const containerClass = `lottie-container${classNamePosfix}`;
  return <div role="img" aria-labelledby={name} className={containerClass} ref={container} />;
}
