import * as ActionTypes from './actionTypes';
import { IUserPeerGroup, IUserStat } from './types';
import { parseGenericStat, parseStringStat } from './utils';

export const initStatsAction: (userId: number) => ActionTypes.IInitStatsAction = (userId) => ({
  type: ActionTypes.STATS_INIT,
  userId,
});

export const loadStatsAction: (userId?: number) => ActionTypes.ILoadStatsAction = (userId) => ({
  type: ActionTypes.STATS_LOAD,
  userId,
});

export const statsReadyAction: () => ActionTypes.IStatsReadyAction = () => ({
  type: ActionTypes.STATS_READY,
});

export const updateStatsAction: (stats: IUserStat[], userId?: number) => ActionTypes.IUpdateStatsAction = (
  stats,
  userId
) => ({
  type: ActionTypes.STATS_UPDATE,
  stats,
  userId,
});

export const saveStatsQueueAction: (userId?: number) => ActionTypes.ISaveStatsQueueAction = (userId) => ({
  type: ActionTypes.STATS_QUEUE_SAVE,
  userId,
});

export const saveStatsQueueFailedAction: (msg: string) => ActionTypes.ISaveStatsQueueFailedAction = (msg) => ({
  type: ActionTypes.STATS_QUEUE_SAVE_FAILED,
  msg,
});

export const saveStatsQueueSuccessAction: (
  statsQueue: IUserStat[],
  userId?: number
) => ActionTypes.ISaveStatsQueueSuccessAction = (statsQueue, userId) => ({
  type: ActionTypes.STATS_QUEUE_SAVE_SUCCESS,
  statsQueue,
  userId,
});

export const addStatsQueueAction: (stats: IUserStat[], userId?: number) => ActionTypes.IAddStatsQueueAction = (
  stats,
  userId
) => ({
  type: ActionTypes.STATS_ADD_QUEUE,
  stats,
  userId,
});

export const updateStatsQueueAction: (
  statsQueue: IUserStat[],
  userId?: number
) => ActionTypes.IUpdateStatsQueueAction = (statsQueue, userId) => ({
  type: ActionTypes.STATS_UPDATE_QUEUE,
  statsQueue,
  userId,
});

export const updateGenericStat: (
  statId: string,
  payload: string | Partial<IUserStat>,
  userId?: number
) => ActionTypes.IAddStatsQueueAction = (statId, payload, userId) => {
  const stats: IUserStat[] = [parseGenericStat(statId, payload)];
  return addStatsQueueAction(stats, userId);
};

export const updateNumericStat: (
  statId: string,
  payload: number,
  userId?: number
) => ActionTypes.IAddStatsQueueAction = (statId, payload, userId) => {
  const stats: IUserStat[] = [parseGenericStat(statId, payload)];
  return addStatsQueueAction(stats, userId);
};

export const updateBooleanStat: (
  statId: string,
  payload: boolean,
  userId?: number
) => ActionTypes.IAddStatsQueueAction = (statId, payload, userId) => {
  const stats: IUserStat[] = [parseGenericStat(statId, payload)];
  return addStatsQueueAction(stats, userId);
};

export const updateStringStat: (
  statId: string,
  payload: string,
  userId?: number
) => ActionTypes.IAddStatsQueueAction = (statId, payload, userId) => {
  const stats: IUserStat[] = [parseStringStat(statId, payload)];
  return addStatsQueueAction(stats, userId);
};

export const loadPeerGroupsAction: (userId?: number) => ActionTypes.ILoadPeerGroupsAction = (userId) => ({
  type: ActionTypes.STATS_PEERGROUPS_LOAD,
  userId,
});

export const updatePeerGroupsAction: (
  peergroups: IUserPeerGroup[],
  userId?: number
) => ActionTypes.IUpdatePeerGroupsAction = (peergroups, userId) => ({
  type: ActionTypes.STATS_PEERGROUPS_UPDATE,
  peergroups,
  userId,
});

export const saveAgeGroupAction: (ageGroup: number) => ActionTypes.ISaveAgeGroupsAction = (ageGroup: number) => ({
  type: ActionTypes.STATS_AGE_GROUP_SAVE,
  ageGroup,
});
