import { SharedErrors } from 'web_core_library';
import { WorkoutTrial } from 'web_unity_engine';

export interface IResult {
  timestamp: number;
  result: number;
}

export interface IBaseResult extends IResult {
  configVersion: number;
  configVariant: string;
  trainingId: number;
  sessionId: number;
  cycleId: number;
  workoutId: number;
  timestamp: number;
  localTs: number;
  result: number;
  gameHistory: string;
  startDifficulty: number;
  endDifficulty: number;
  meanDifficulty: number;
  minDifficulty: number;
  maxDifficulty: number;
  avgBadReactionTime: number;
  avgGoodReactionTime: number;
  trials?: WorkoutTrial[];
}

export interface ILocalResult extends IBaseResult {
  // timestamp of loading from backend or saving
  // means data was synchronized with backend
  syncTimestamp: number;
}

export interface ITrainingProgressResponse {
  filter: {
    uid: number;
    trainingid: number;
    limit?: number;
    workoutid?: number;
    page?: number;
    workoutids?: number[];
  };
  trainingprogress: IBaseResult[];
}

export interface IProgressCacheValue {
  // full progress of the user
  progress: ILocalResult[];
  // timestamp of the last progress entry
  // to detect if we need to fetch anything new
  timestamp: number;
}

export class ProgressError extends SharedErrors.BaseError {
  constructor(message: string) {
    super(message);
    Object.setPrototypeOf(this, ProgressError.prototype);
    this.feature = 'Progress2';
  }
}
