import { LottieAnimation } from '@web_opxp/lottie';
import React from 'react';

export interface IHeroSectionCircleProps {
  className?: string;
  imgSrc?: string;
  lottie?: string;
  lottieAuto?: boolean;
  lottieLoop?: boolean;
}

export default function HeroSectionCircle({
  className,
  imgSrc,
  lottie,
  lottieAuto,
  lottieLoop,
}: IHeroSectionCircleProps) {
  const [divHeight, setDivHeight] = React.useState(0);
  const divRef = React.useRef<HTMLDivElement>(null);
  const handleResize = React.useCallback(
    (entries: ResizeObserverEntry[]) => {
      if (!Array.isArray(entries)) {
        return;
      }
      const entry = entries[0];
      const height = entry.contentRect.height;
      setDivHeight(height);
    },
    [setDivHeight]
  );

  React.useLayoutEffect(() => {
    if (!divRef.current) {
      return;
    }
    const { height } = divRef.current.getBoundingClientRect();
    setDivHeight(height);

    let RO: ResizeObserver | null = new ResizeObserver(handleResize);
    RO.observe(divRef.current);
    return () => {
      if (!RO) {
        return;
      }
      RO.disconnect();
      RO = null;
    };
  }, [handleResize]);

  return (
    <div className={className} ref={divRef} style={{ '--height': `${divHeight}px` } as React.CSSProperties}>
      <div className="illustration-wrapper">
        {imgSrc ? <img className="hero-section-image" src={imgSrc} alt="illustration" /> : null}
        {lottie ? <LottieAnimation name={lottie} autoplay={lottieAuto} loop={lottieLoop} /> : null}
      </div>
    </div>
  );
}
