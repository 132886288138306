import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IExperimentVariant } from './types';

export const statePropName = 'experiments';

export interface IExperimentsState {
  loading: boolean;
  experiments: IExperimentVariant[];
}

export const initialState: IExperimentsState = {
  loading: true,
  experiments: [],
};

export interface initExperimentsPayload {
  experiments: number[];
}

const experimentsSlice = createSlice({
  name: statePropName,
  initialState,
  reducers: {
    initExperiments: (state: IExperimentsState, action: PayloadAction<initExperimentsPayload>) => {},
    experimentsReady: (state: IExperimentsState, action: PayloadAction<IExperimentVariant[]>) => {
      state.loading = false;
      state.experiments = action.payload;
    },
  },
});

export const ExperimentsActions = experimentsSlice.actions;
export default experimentsSlice.reducer;
