export enum EDomain {
  Assessment = 0,
  Speed = 1,
  Reasoning = 2,
  Attention = 3,
  Memory = 4,
  SelfRegulation = 5,
  CFQScore = 100,
  Forgetfulness = 101,
  Distractibility = 102,
  FalseTriggering = 103,
  Mood = 20001,
}
