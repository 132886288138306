import * as ActionTypes from './actionTypes';
import { IBaseResult, ILocalResult } from './types';
import { findWorkoutProgress, getRangeId } from './utils';

export interface IProgress2State {
  loading: boolean;
  saving: boolean;
  ready: boolean;
  // global progress storage
  // may be should not store the full progress in state
  // but store it in some custom service to prevent
  // redux from complaining about too big state
  fullProgress?: ILocalResult[];
  // shortcuts when global progress is not loaded yet
  // undefined - progress not loaded
  // null - progress loaded but empty
  history: {
    // history for training calendar
    [range: string]: IBaseResult[] | null | undefined;
    // progress for particular workouts
    [workoutId: number]: IBaseResult[] | null | undefined;
  };
  last: {
    // last progress entries for particular workouts
    [workoutId: number]: IBaseResult | null | undefined;
  };
  // data for custom users (group admin feature)
  users: {
    [userId: number]: {
      fullProgress?: ILocalResult[];
      history?: {
        [range: string]: IBaseResult[] | null | undefined;
        [workoutId: number]: IBaseResult[] | null | undefined;
      };
      last?: {
        [workoutId: number]: IBaseResult | null | undefined;
      };
    };
  };
}

export const initialState: IProgress2State = {
  loading: false,
  saving: false,
  ready: false,
  history: {},
  last: {},
  users: {},
};

export const statePropName = 'progress2';

export default function progress2Reducer(
  state: IProgress2State = initialState,
  action: ActionTypes.IProgress2Actions
): IProgress2State {
  switch (action.type) {
    case ActionTypes.PROGRESS2_READY: {
      return {
        ...state,
        ready: true,
      };
    }
    case ActionTypes.PROGRESS2_LOAD: {
      return {
        ...state,
        loading: true,
      };
    }
    case ActionTypes.PROGRESS2_LOADED: {
      return {
        ...state,
        loading: false,
      };
    }
    case ActionTypes.PROGRESS2_UPDATE: {
      const { progress, userId } = action;
      if (!userId) {
        return {
          ...state,
          fullProgress: progress,
          // reset explicit state since its not needed anymore
          history: {},
          last: {},
        };
      }
      const userState = {
        ...state.users[userId],
        fullProgress: progress,
        // reset explicit state since its not needed anymore
        history: undefined,
        last: undefined,
      };
      return {
        ...state,
        users: {
          ...state.users,
          [userId]: userState,
        },
      };
    }
    case ActionTypes.PROGRESS2_SAVE_START: {
      return {
        ...state,
        saving: true,
      };
    }
    case ActionTypes.PROGRESS2_SAVE_END: {
      return {
        ...state,
        saving: false,
      };
    }
    case ActionTypes.PROGRESS2_UPDATE_WORKOUT_HISTORY: {
      const { endTimestamp, progress, startTimestamp, userId } = action;
      const rangeId = getRangeId(startTimestamp, endTimestamp);
      if (!userId) {
        const history = {
          ...state.history,
          [rangeId]: progress,
        };
        return {
          ...state,
          history,
        };
      }
      const userProgressState = state.users[userId] || {};
      const userHistory = userProgressState.history || {};
      const userState = {
        ...userProgressState,
        history: {
          ...userHistory,
          [rangeId]: progress,
        },
      };
      return {
        ...state,
        users: {
          ...state.users,
          [userId]: userState,
        },
      };
    }
    case ActionTypes.PROGRESS2_UPDATE_LAST: {
      const { workoutIds, progress, userId } = action;
      if (!workoutIds) {
        return state;
      }
      if (!userId) {
        const last = {
          ...state.last,
        };
        workoutIds.forEach((workoutId) => {
          const result = findWorkoutProgress(progress, workoutId);
          last[workoutId] = result ?? null;
        });
        return {
          ...state,
          last,
        };
      }
      const userProgressState = state.users[userId] || {};
      const userLast = userProgressState.last || {};
      const updatedLast = {
        ...userLast,
      };
      workoutIds.forEach((workoutId) => {
        const result = findWorkoutProgress(progress, workoutId);
        updatedLast[workoutId] = result ?? null;
      });
      const updatedUserProgressState = {
        ...userProgressState,
        last: updatedLast,
      };
      return {
        ...state,
        users: {
          ...state.users,
          [userId]: updatedUserProgressState,
        },
      };
    }
    case ActionTypes.PROGRESS2_UPDATE_WORKOUT: {
      const { workoutId, progress, userId } = action;
      if (!userId) {
        const history = {
          ...state.history,
          [workoutId]: progress,
        };
        return {
          ...state,
          history,
        };
      }
      const userProgressState = state.users[userId] || {};
      const userHistory = userProgressState.history || {};
      const updatedHistory = {
        ...userHistory,
        [workoutId]: progress,
      };
      const updatedUserProgressState = {
        ...userProgressState,
        history: updatedHistory,
      };
      return {
        ...state,
        users: {
          ...state.users,
          [userId]: updatedUserProgressState,
        },
      };
    }
  }
  return state;
}
