import { WorkoutUnityResult } from 'web_unity_engine';
import * as ActionTypes from './actionTypes';
import { IBaseResult, ILocalResult } from './types';

export const initProgress2Action: (userId: number) => ActionTypes.IInitProgress2Action = (userId) => ({
  type: ActionTypes.PROGRESS2_INIT,
  userId,
});

export const progress2ReadyAction: () => ActionTypes.IProgress2ReadyAction = () => ({
  type: ActionTypes.PROGRESS2_READY,
});

export const loadProgress2Action: (userId?: number) => ActionTypes.ILoadProgress2Action = (userId) => ({
  type: ActionTypes.PROGRESS2_LOAD,
  userId,
});

export const progress2LoadedAction: () => ActionTypes.IProgress2LoadedAction = () => ({
  type: ActionTypes.PROGRESS2_LOADED,
});

export const updateProgress2Action: (
  progress: ILocalResult[],
  userId?: number
) => ActionTypes.IUpdateProgress2Action = (progress, userId) => ({
  type: ActionTypes.PROGRESS2_UPDATE,
  progress,
  userId,
});

/**
 * parse unity result and save progress to backend
 * @param {string} variant config variant
 * @param {number} version config version
 * @param {number} cycleId id of the current cycle
 * @param {number} sessionId id of the current session
 * @param {number} workoutId id of the workout
 * @param {WorkoutUnityResult} progress result object from unity
 */
export const saveProgress2Action: (
  variant: string,
  version: number,
  cycleId: number,
  sessionId: number,
  workoutId: number,
  progress: WorkoutUnityResult
) => ActionTypes.ISaveProgress2Action = (variant, version, cycleId, sessionId, workoutId, progress) => ({
  type: ActionTypes.PROGRESS2_SAVE,
  variant,
  version,
  cycleId,
  sessionId,
  workoutId,
  progress,
});

/**
 * save score for workout (CFQ) to backend
 * @param {string} variant config variant
 * @param {number} version config version
 * @param {number} cycleId id of the current cycle
 * @param {number} sessionId id of the current session
 * @param {number} workoutId id of the workout
 * @param {number} progress score gained
 */
export const saveProgress2ScoreAction: (
  variant: string,
  version: number,
  cycleId: number,
  sessionId: number,
  workoutId: number,
  score: number
) => ActionTypes.ISaveProgress2ScoreAction = (variant, version, cycleId, sessionId, workoutId, score) => ({
  type: ActionTypes.PROGRESS2_SAVE_SCORE,
  variant,
  version,
  cycleId,
  sessionId,
  workoutId,
  score,
});

export const startSaveProgress2Action: () => ActionTypes.IStartSaveProgress2Action = () => ({
  type: ActionTypes.PROGRESS2_SAVE_START,
});

export const endSaveProgress2Action: () => ActionTypes.IEndSaveProgress2Action = () => ({
  type: ActionTypes.PROGRESS2_SAVE_END,
});

export const loadProgress2HistoryAction: (
  startTimestamp: number,
  endTimestamp: number,
  userId?: number
) => ActionTypes.ILoadProgress2HistoryAction = (startTimestamp, endTimestamp, userId) => ({
  type: ActionTypes.PROGRESS2_HISTORY_LOAD,
  startTimestamp,
  endTimestamp,
  userId,
});

export const updateProgress2HistoryAction: (
  startTimestamp: number,
  endTimestamp: number,
  progress: IBaseResult[],
  userId?: number
) => ActionTypes.IUpdateProgress2HistoryAction = (startTimestamp, endTimestamp, progress, userId) => ({
  type: ActionTypes.PROGRESS2_UPDATE_WORKOUT_HISTORY,
  startTimestamp,
  endTimestamp,
  progress,
  userId,
});

export const loadLastProgress2Action: (
  workoutIds: number[],
  userId?: number
) => ActionTypes.ILoadLastProgress2Action = (workoutIds, userId) => ({
  type: ActionTypes.PROGRESS2_LOAD_LAST,
  workoutIds,
  userId,
});

export const updateWorkoutLastProgress2Action: (
  workoutIds: number[],
  progress: IBaseResult[],
  userId?: number
) => ActionTypes.IUpdateWorkoutLastProgress2Action = (workoutIds, progress, userId) => ({
  type: ActionTypes.PROGRESS2_UPDATE_LAST,
  workoutIds,
  progress,
  userId,
});

export const loadWorkoutsProgress2Action: (
  workoutIds: number[],
  userId?: number
) => ActionTypes.ILoadWorkoutsProgress2Action = (workoutIds, userId) => ({
  type: ActionTypes.PROGRESS2_LOAD_WORKOUTS,
  workoutIds,
  userId,
});

export const updateWorkoutProgress2Action: (
  workoutId: number,
  progress: IBaseResult[],
  userId?: number
) => ActionTypes.IUpdateWorkoutProgress2Action = (workoutId, progress, userId) => ({
  type: ActionTypes.PROGRESS2_UPDATE_WORKOUT,
  workoutId,
  progress,
  userId,
});

export const cleanProgress2Action: () => ActionTypes.ICleanProgress2Action = () => ({
  type: ActionTypes.PROGRESS2_CLEAN,
});

export const cleanProgress2DoneAction: () => ActionTypes.ICleanProgress2DoneAction = () => ({
  type: ActionTypes.PROGRESS2_CLEAN_DONE,
});
