import React, { CSSProperties, PropsWithChildren, useCallback, useEffect, useRef, useState } from 'react';
import './Banner.scss';

interface IBannerProps {
  show: boolean;
  color?: 'red' | 'green' | 'blue';
  onClose?: () => void;
}

export default function Banner({ show, color = 'red', children, onClose }: PropsWithChildren<IBannerProps>) {
  const [visible, setVisibility] = useState(false);
  const [bannerHeight, setHeight] = useState(0);
  const bannerRef = useRef<HTMLDivElement>(null);
  const hideBanner = useCallback(() => setVisibility(false), []);
  useEffect(() => {
    if (bannerRef.current) {
      const height = bannerRef.current.offsetHeight;
      setHeight(height);
    }
    if (!show) {
      hideBanner();
      return;
    }
    setVisibility(true);
  }, [hideBanner, show, setHeight]);
  const containerStyle: CSSProperties = {
    visibility: visible ? 'visible' : 'hidden',
    maxHeight: visible ? `${bannerHeight}px` : '0px',
  };
  const bannerStyle: CSSProperties = {
    transform: visible ? 'translateY(0)' : 'translateY(-100%)',
  };
  const closeBannerHandler = useCallback(() => {
    hideBanner();
    onClose && onClose();
  }, [hideBanner, onClose]);
  return (
    <div className="banner-container" style={containerStyle}>
      <div className={`banner ${color}`} style={bannerStyle} ref={bannerRef}>
        <div className="banner-main">{children}</div>
        <span className="banner-close" onClick={closeBannerHandler}>
          X
        </span>
      </div>
    </div>
  );
}
