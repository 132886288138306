import { CombinedState } from 'redux';
import * as Reducer from './reducer';
import {
  calculateAccuracy,
  calculateBadRuns,
  calculateGoodRuns,
  calculateWorkoutGoal,
  cleanupProgress,
  filterByDay,
  filterByNonAssessment,
  filterByStartDifficulty,
  filterBySyncTimestamp,
  filterProgressByTimestamp,
  filterProgressByWorkout,
  findProgressForCycleAndSession,
  getLastProgressEntry,
  getMaxResult,
  getRangeId,
  mergeWorkoutsProgress,
  sortProgress,
} from './utils';

export interface IAppState extends CombinedState<unknown> {
  // this feature state
  [Reducer.statePropName]: Reducer.IProgress2State;
}

const getState = (state: IAppState) => state[Reducer.statePropName];

export const isProgress2Loading = (state: IAppState) => getState(state).loading;

export const isProgress2Ready = (state: IAppState) => getState(state).ready;

export const isProgress2Saving = (state: IAppState) => getState(state).saving;

const getUserState = (state: IAppState, userId: number) => getState(state).users[userId] ?? {};

export const getFullProgress2 = (state: IAppState, userId?: number) => {
  if (!userId) {
    return getState(state).fullProgress;
  }
  return getUserState(state, userId).fullProgress;
};

export const isFullProgress2Loaded = (state: IAppState, userId?: number) => !!getFullProgress2(state, userId);

export const getProgress2ToSync = (state: IAppState) => {
  const progress = getFullProgress2(state);
  const unsynced = filterBySyncTimestamp(progress);
  return unsynced;
};

const getWorkoutProgress2 = (state: IAppState, workoutId: number, userId?: number) => {
  const progress = getFullProgress2(state, userId);
  return filterProgressByWorkout(progress, workoutId);
};

export const getMergedProgressForWorkouts = (state: IAppState, workoutIds: number[], userId?: number) => {
  const progressForWorkouts = workoutIds.map((workoutId) => getWorkoutProgress2(state, workoutId, userId));
  return mergeWorkoutsProgress(progressForWorkouts);
};

// this selector is modified in comparison to previous version to hide implementation details
// return only the value which is needed instead of the whole result object
export const getHighscoreResultWithSiblings = (state: IAppState, workoutIds: number[]) => {
  const progress = getMergedProgressForWorkouts(state, workoutIds);
  const maxResult = getMaxResult(progress);
  return maxResult ? maxResult.result : undefined;
};

const getLastProgressForCycleAndSessionFromHistory = (
  state: IAppState,
  workoutId: number,
  sessionId: number,
  cycleId: number
) => {
  const allWorkoutProgress = getWorkoutProgress2(state, workoutId);
  if (!allWorkoutProgress) {
    return null;
  }
  const filteredProgress = findProgressForCycleAndSession(allWorkoutProgress, workoutId, sessionId, cycleId);
  if (filteredProgress.length === 0) {
    return null;
  }
  const sortedProgress = sortProgress(filteredProgress);
  return sortedProgress[sortedProgress.length - 1];
};

// this selector is used in assessment feature and is modifed in comparison to previous version
// to hide implementation details and only return data which is required by consumer
export const getLastProgressForEachWorkoutForCycleAndSession = (
  state: IAppState,
  workoutIds: number[],
  sessionId: number,
  cycleId: number
) => {
  const workoutsProgress = workoutIds.map((wId) =>
    getLastProgressForCycleAndSessionFromHistory(state, wId, sessionId, cycleId)
  );
  return cleanupProgress(workoutsProgress).map((progress) => ({
    workoutId: progress.workoutId,
    result: progress.result,
  }));
};

const getWorkoutLastProgress = (state: IAppState, workoutId: number, last = 1) => {
  const allWorkoutProgress = getWorkoutProgress2(state, workoutId);
  if (!allWorkoutProgress) {
    return null;
  }
  return getLastProgressEntry(allWorkoutProgress, last);
};

// this selectors returns last n-th entry from the full progress instead of last property
// we must make sure that wherever its used we are preloading full progress before
export const getLastWorkoutScore = (state: IAppState, workoutId: number, last = 1) => {
  const lastProgress = getWorkoutLastProgress(state, workoutId, last);
  if (!lastProgress) {
    return 0;
  }
  return lastProgress.result;
};

// this selector must be used instead of hasCompletedTodaysChallenge to avoid unnecessary sharing
// knowledge and introducing dependency from date utils, comparison with today must do the consumer
export const getLastWorkoutTimestamp = (state: IAppState, workoutId: number) => {
  const lastProgress = getWorkoutLastProgress(state, workoutId);
  if (!lastProgress) {
    return 0;
  }
  return lastProgress.timestamp;
};

export const getLastWorkoutAccuracy = (state: IAppState, workoutId: number) => {
  const lastProgress = getWorkoutLastProgress(state, workoutId);
  if (!lastProgress) {
    // if progress not found we must differentiate it from 0 accuracy
    return null;
  }
  return calculateAccuracy(lastProgress);
};

export const getLastWorkoutDifficulty = (state: IAppState, workoutId: number) => {
  const lastProgress = getWorkoutLastProgress(state, workoutId);
  if (!lastProgress) {
    return 0;
  }
  return lastProgress.endDifficulty;
};

export const getLastWorkoutStartDifficulty = (state: IAppState, workoutId: number) => {
  const lastProgress = getWorkoutLastProgress(state, workoutId);
  if (!lastProgress) {
    return 0;
  }
  return lastProgress.startDifficulty;
};

export const getLastWorkoutBadRuns = (state: IAppState, workoutId: number) => {
  const lastProgress = getWorkoutLastProgress(state, workoutId);
  if (!lastProgress) {
    return 0;
  }
  return calculateBadRuns(lastProgress);
};

export const getLastWorkoutGoodRuns = (state: IAppState, workoutId: number) => {
  const lastProgress = getWorkoutLastProgress(state, workoutId);
  if (!lastProgress) {
    return 0;
  }
  return calculateGoodRuns(lastProgress);
};

export const getCompletionCountWithSiblings = (state: IAppState, workoutIds: number[]) => {
  const progress = getMergedProgressForWorkouts(state, workoutIds);
  return progress ? progress.length : 0;
};

export const getWorkoutCompletionCount = (state: IAppState, workoutId: number) => {
  const progress = getWorkoutProgress2(state, workoutId);
  return progress ? progress.length : 0;
};

export const getTotalCompletionCount = (state: IAppState) =>
  filterByNonAssessment(getFullProgress2(state))?.length ?? 0;

export const getCompletionCountForLevel = (state: IAppState, workoutId: number, difficulty: number) => {
  const allProgress = getWorkoutProgress2(state, workoutId);
  return allProgress ? filterByStartDifficulty(allProgress, difficulty).length : 0;
};

export const getNextWorkoutGoalWithSibling = (state: IAppState, workoutIds: number[]) => {
  const progress = getMergedProgressForWorkouts(state, workoutIds);
  if (!progress) {
    return 0;
  }
  return calculateWorkoutGoal(progress);
};

export const getCompletionCountForDate = (state: IAppState, date: Date) => {
  const progress = getFullProgress2(state) ?? [];
  return filterByDay(filterByNonAssessment(progress), date).length;
};

export const getStreakForDate = (state: IAppState, date: Date) => {
  const progress = getFullProgress2(state) ?? [];
  const current = new Date(date.getTime());
  let streak = 0;
  let shouldStop = false;
  while (!shouldStop) {
    const completed = filterByDay(filterByNonAssessment(progress), current).length;
    if (!completed) {
      shouldStop = true;
    } else {
      streak += 1;
      current.setDate(current.getDate() - 1);
    }
  }
  return streak;
};

/******************************************************************************************/
// these selectors are used in several edge cases when we need to get progress data before
// full progress loading happens. They must be used with caution and only in combination
// with explicit progress loading in case no data is available
const getLastProgress = (state: IAppState, userId?: number) => {
  if (!userId) {
    return getState(state).last;
  }
  return getUserState(state, userId).last ?? {};
};

export const getWorkoutLastProgress2WithFallback = (state: IAppState, workoutId: number, userId?: number) => {
  // check full progress
  if (isFullProgress2Loaded(state, userId)) {
    const workoutProgress = getWorkoutProgress2(state, workoutId, userId);
    return getLastProgressEntry(workoutProgress) ?? null;
  }
  // fallback to explicit property when full progress not loaded yet
  const lastProgress = getLastProgress(state, userId);
  return lastProgress[workoutId];
};

const getProgressHistory = (state: IAppState, userId?: number) => {
  if (!userId) {
    return getState(state).history;
  }
  return getUserState(state, userId).history ?? {};
};

// selector from main state with fallback to explicit state prop
export const getProgressHistoryFromTimestamp = (
  state: IAppState,
  startTimestamp: number,
  endTimestamp: number,
  userId?: number
) => {
  // check full progress
  if (isFullProgress2Loaded(state, userId)) {
    const progress = getFullProgress2(state, userId);
    return filterProgressByTimestamp(filterByNonAssessment(progress), startTimestamp, endTimestamp);
  }
  // fallback to explicit state property
  const history = getProgressHistory(state, userId);
  const rangeId = getRangeId(startTimestamp, endTimestamp);
  const result = history[rangeId];
  return !result ? result : filterByNonAssessment(result);
};

// selector from main state with fallback to explicit state prop
export const getWorkoutProgressHistory = (state: IAppState, workoutId: number, userId?: number) => {
  // check full progress
  if (isFullProgress2Loaded(state, userId)) {
    // if full progress is loaded - absense of item means no progress
    const workoutProgress = getWorkoutProgress2(state, workoutId, userId);
    // we must return null in case of progress is loaded
    return workoutProgress.length > 0 ? workoutProgress : null;
  }
  // fallback to explicit state property
  const history = getProgressHistory(state, userId);
  return history[workoutId];
};

export const isProgress2HistoryLoadedForWorkouts = (state: IAppState, workoutIds: number[], userId?: number) => {
  const progressLoaded = (id: number) => {
    const progress = getWorkoutProgressHistory(state, id, userId);
    return !!progress || progress === null;
  };
  return workoutIds.every(progressLoaded);
};

export const getWorkoutLastProgressWithSiblings = (state: IAppState, workoutIds: number[], userId?: number) => {
  // check full progress
  if (isFullProgress2Loaded(state, userId)) {
    const progress = getMergedProgressForWorkouts(state, workoutIds, userId);
    return getLastProgressEntry(progress);
  }
  const progressForWorkouts = workoutIds.map((workoutId) =>
    getWorkoutLastProgress2WithFallback(state, workoutId, userId)
  );
  return getLastProgressEntry(progressForWorkouts);
};

export const getWorkoutLastScoreWithSiblings = (state: IAppState, workoutIds: number[], userId?: number) => {
  const lastProgress = getWorkoutLastProgressWithSiblings(state, workoutIds, userId);
  if (!lastProgress) {
    return 0;
  }
  return lastProgress.result;
};

export const isLastProgress2LoadedForWorkouts = (state: IAppState, workoutIds: number[], userId?: number) => {
  const progressLoaded = (id: number) => {
    const progress = getWorkoutLastProgress2WithFallback(state, id, userId);
    return !!progress || progress === null;
  };
  return workoutIds.every(progressLoaded);
};
