import React, { CSSProperties, useCallback, useEffect, useRef, useState } from 'react';
import ChevronLeftIcon from '../icons/ChevronLeftIcon';
import ChevronRightIcon from '../icons/ChevronRightIcon';
import './Carousel.scss';

export interface ICarouselProps {
  animationTime: number;
  slides: React.ReactNode[];
}

export const Carousel: React.FC<ICarouselProps> = ({ animationTime, slides }) => {
  const numberOfSlides = slides.length;
  // current slide
  const [current, setCurrent] = useState(0);
  // scrolling is paused
  const [paused, setPause] = useState(false);
  // timeout handle
  const animationHandle = useRef<any>();
  const slideRight = useCallback(() => {
    const next = (current + 1) % numberOfSlides;
    setCurrent(next);
  }, [current, numberOfSlides]);
  const slideLeft = useCallback(() => {
    const next = (numberOfSlides + current - 1) % numberOfSlides;
    setCurrent(next);
  }, [current, numberOfSlides]);
  const stopSlider = () => {
    if (!animationHandle.current) {
      // do not stop animation if already stopped
      return;
    }
    clearTimeout(animationHandle.current);
    animationHandle.current = undefined;
  };
  const runSlider = useCallback(() => {
    if (animationHandle.current) {
      // do not start animation if already started
      return;
    }
    if (!animationTime) {
      // do not start animation if animation time is not set
      return;
    }
    animationHandle.current = setTimeout(slideRight, animationTime);
  }, [animationTime, slideRight]);
  useEffect(() => {
    if (!animationTime) {
      // do not start animation if animation time is not set
      return;
    }
    // start animation if not paused
    if (paused) {
      return;
    }
    runSlider();
    // stop animation
    return stopSlider;
  });
  const carouselSlides = slides.map((slide, index) => {
    return (
      <div key={index} className="carousel-item">
        {slide}
      </div>
    );
  });
  // define css variables needed for animation
  const carouselStyles = {
    '--num-slides': numberOfSlides,
    '--current-slide': current,
  };
  // do not render left nav if first slide
  const leftNav =
    current === 0 ? null : (
      <button onClick={slideLeft}>
        <ChevronLeftIcon width={20} height={20} />
      </button>
    );
  // do not render right nav if last slide
  const rightNav =
    current === slides.length - 1 ? null : (
      <button onClick={slideRight}>
        <ChevronRightIcon width={20} height={20} />
      </button>
    );
  const pauseSlider = () => {
    setPause(true);
    stopSlider();
  };
  const unpauseSlider = () => {
    setPause(false);
    runSlider();
  };
  return (
    <div
      className="carousel-slider"
      style={carouselStyles as CSSProperties}
      onMouseEnter={pauseSlider}
      onMouseLeave={unpauseSlider}>
      <div className="nav-button">{leftNav}</div>
      <div className="carousel-container">
        <div className="carousel-content">{carouselSlides}</div>
      </div>
      <div className="nav-button">{rightNav}</div>
    </div>
  );
};

export default Carousel;
