import { FirebaseOptions } from 'firebase/app';
import { getMessaging, getToken, isSupported, MessagePayload, Messaging, onMessage } from 'firebase/messaging';
import { getApp } from './app';
import { TMessageHandler } from './types';

let messagingApp: Messaging | undefined;
let userId: number | undefined;

/**
 * Core firebase app must be initialized before you call this
 * otherwise it will throw an error
 */
export const init = async () => {
  try {
    const isMessagingSupported = await isSupported();
    if (!isMessagingSupported) {
      console.warn('Messaging app is not supported by this browser!');
      return;
    }
    const app = getApp();
    messagingApp = getMessaging(app);
    await initServiceWorker(app.options);
  } catch (error) {
    console.warn(error);
    return;
  }
};

export async function initServiceWorker(firebaseConfig: FirebaseOptions) {
  if (!navigator || !navigator.serviceWorker) {
    console.warn('No ServiceWorker in environment detected!');
    return;
  }

  const registrations = await navigator.serviceWorker.getRegistrations();
  for (const reg of registrations) {
    if (reg.scope.includes('push-scope')) {
      sendConfigToSw(reg, firebaseConfig);
    }
  }
}

export function sendConfigToSw(swReg: ServiceWorkerRegistration, firebaseConfig: FirebaseOptions) {
  let sw: ServiceWorker | null = null;
  if (swReg.installing) {
    sw = swReg.installing;
  } else if (swReg.waiting) {
    sw = swReg.waiting;
  } else if (swReg.active) {
    sw = swReg.active;
  }
  if (sw) {
    console.info('sending config to service worker', sw);
    sw.postMessage({
      command: 'firebase.init',
      config: firebaseConfig,
    });
  }
}

export function addMessageHandler(messageHandler: TMessageHandler) {
  if (!navigator || !navigator.serviceWorker) {
    console.warn('No ServiceWorker in environment detected!');
    return;
  }
  // create handler wrapper
  const handler = (e: MessageEvent) => {
    const data = e.data;
    if (!data) {
      return;
    }
    if (data.command !== 'firebase.message') {
      return;
    }
    const message = data.message as MessagePayload;
    console.log('received message from worker', message);
    messageHandler(message);
  };
  // subscribe to event
  navigator.serviceWorker.addEventListener('message', handler);
  // return unsubscribe function
  return () => navigator.serviceWorker.removeEventListener('message', handler);
}

export const startMessaging = (messageHandler: TMessageHandler) => {
  if (!messagingApp) {
    return;
  }
  const backgroundUnsubscribe = addMessageHandler(messageHandler);
  const foregroundUnsubscribe = onMessage(messagingApp, messageHandler);
  return () => {
    backgroundUnsubscribe && backgroundUnsubscribe();
    foregroundUnsubscribe();
  };
};

export const getPushToken = (vapidKey: string) => {
  if (!messagingApp) {
    return;
  }
  return getToken(messagingApp, { vapidKey });
};

export const setUserId = (_userId: number) => {
  userId = _userId;
};

export const getUserId = () => userId;
