import { AxiosResponse } from 'axios';
import { all, call, put, select, take, takeEvery } from 'redux-saga/effects';
import { ApiService, HttpError, TApiFetchResponse } from 'web_core_library';
import { LanguageService } from '../language';
import * as ActionTypes from './actionTypes';
import * as Actions from './actions';
import StatsCache from './cache';
import * as Selectors from './selectors';
import StatsService from './statsService';
import * as Types from './types';
import {
  convertIUserStats2IUserStatGets,
  convertIUserStats2IUserStatPosts,
  updateExistingStatsArrayWithAnother,
} from './utils';

export function* initStatsSaga({ userId }: ActionTypes.IInitStatsAction) {
  yield call(StatsService.init, ApiService);
  yield call(StatsCache.init, userId);
  yield call(loadStatsWithCacheSaga);
  yield put(Actions.statsReadyAction());
}

export function* loadUserStatsSaga({ userId }: ActionTypes.ILoadStatsAction) {
  if (!userId) {
    // current user - use cache
    yield call(loadStatsWithCacheSaga);
    return;
  }
  // if user is set - its a custom user and we should go without caching
  yield call(loadStatsWithoutCache, userId);
}

export function* loadStatsWithCacheSaga() {
  let stats: Types.IUserStatGet[] | undefined = undefined;
  const cacheValue: Types.IUserStatGetCache | undefined = yield call(StatsCache.getStats);
  const isExpired: boolean = yield call(StatsCache.isExpired, cacheValue);
  if (!cacheValue || isExpired) {
    // start loading data from api if cached data not present or expired
    stats = yield call(fetchStatsFromApi);
    if (stats) {
      yield call(updateCache, stats);
    }
  }
  if (!stats) {
    if (!cacheValue) {
      // Show error if no stats available from API nor cached data
      const t = LanguageService.getTFunction();
      throw new Types.StatsError(t('common:Errors.Stats.LoadStatsFailed'));
    }
    // update state from cache regardless of cache expiration
    stats = cacheValue.stats;
  }
  yield put(Actions.updateStatsAction(stats));
  yield put(Actions.statsReadyAction());
}

export function* loadStatsWithoutCache(userId?: number) {
  const stats: Types.IUserStatGet[] | undefined = yield call(fetchStatsFromApi, userId);
  if (!stats) {
    // Show error if no stats available from API nor cached data
    const t = LanguageService.getTFunction();
    throw new Types.StatsError(t('common:Errors.Stats.LoadStatsFailed'));
  }
  yield put(Actions.updateStatsAction(stats, userId));
  yield put(Actions.statsReadyAction());
}

export function* fetchStatsFromApi(userId?: number) {
  try {
    // fetch data from api
    const statsResponse: AxiosResponse<Types.IGetUserStatsResult> = yield call(StatsService.getUserStats, userId);
    return statsResponse.data.stats;
  } catch (error) {
    return;
  }
}

export function* updateCache(newData: Types.IUserStatGet[]) {
  //save stats with current timestamp to cache
  try {
    yield call(StatsCache.setStats, newData);
  } catch (error) {
    // failed saving to cache can be safely ignored
  }
}

export function* saveStatsQueueSaga({ userId }: ActionTypes.ISaveStatsQueueAction) {
  const existingQueue: Types.IUserStat[] = yield select(Selectors.getStatsQueueToSave, userId);
  // don't send empty data to backend
  if (!existingQueue || !existingQueue.length) {
    yield put(Actions.saveStatsQueueSuccessAction([]));
    return;
  }
  try {
    yield call(StatsService.saveUserStats, convertIUserStats2IUserStatPosts(existingQueue), userId);
    if (!userId) {
      // if userId not specified we need to cache default users values
      // get stats from cache, update saved and save back to cache
      const cacheValue: Types.IUserStatGetCache | undefined = yield call(StatsCache.getStats);
      if (cacheValue && cacheValue.stats) {
        const changedStats = convertIUserStats2IUserStatGets(existingQueue);
        const stats = updateExistingStatsArrayWithAnother<Types.IUserStatGet>(cacheValue.stats, changedStats);
        yield call(updateCache, stats);
      }
    }
    yield put(Actions.saveStatsQueueSuccessAction(existingQueue, userId));
  } catch (error) {
    const { data, status } = error as HttpError;
    const message = data && data.msg ? data.msg : (error as Error).message;
    // dispatch failed action
    yield put(Actions.saveStatsQueueFailedAction(message));
    // rethrow error to the global handler;
    const t = LanguageService.getTFunction();
    if (status === 0 || status > 500) {
      throw new Types.StatsError(t('common:Errors.Stats.NetworkError_SaveStatsFailed'));
    } else if (status !== 422) {
      // supress the case which must be covered by calling feature
      throw new Types.StatsError(t('common:Errors.Stats.ServerError_SaveStatsFailed'));
    }
  }
}

export function* addQueueSaga({ stats, userId }: ActionTypes.IAddStatsQueueAction) {
  const existingQueue: Types.IUserStat[] = yield select(Selectors.getStatsQueueToSave, userId);
  const updatedQueue = updateExistingStatsArrayWithAnother(existingQueue, stats);
  yield put(Actions.updateStatsQueueAction(updatedQueue, userId));

  const existingStats: Types.IUserStat[] = yield select(Selectors.getStats, userId);
  const updatedStats = updateExistingStatsArrayWithAnother(existingStats, stats);
  yield put(Actions.updateStatsAction(updatedStats, userId));
}

export function* preloadAgeGroupSaga(userId?: number) {
  const ageGroupId: number | null = yield select(Selectors.getAgeGroup, userId);
  if (ageGroupId !== null) {
    // age group already loaded, no need to load again
    return;
  }
  yield put(Actions.loadPeerGroupsAction(userId));
  yield take(ActionTypes.STATS_PEERGROUPS_UPDATE);
}

export function* loadPeerGroupSaga({ userId }: ActionTypes.ILoadPeerGroupsAction) {
  const peergroupsResponse: TApiFetchResponse<typeof StatsService.getUserPeerGroup> = yield call(
    StatsService.getUserPeerGroup,
    userId
  );
  const peergroups = peergroupsResponse.data.peergroups;
  yield put(Actions.updatePeerGroupsAction(peergroups, userId));
}

export function* saveAgeGroup({ ageGroup }: ActionTypes.ISaveAgeGroupsAction) {
  // save peer group
  yield call(StatsService.saveUserAgeGroup, ageGroup);
  // refresh data by fetching new peergroups
  yield put(Actions.loadPeerGroupsAction());
}

export function* preloadStatsSaga() {
  const isStatsLoading: boolean = yield select(Selectors.isStatsLoading);
  if (isStatsLoading) {
    yield take(ActionTypes.STATS_READY);
  }
}

export default function* statsWatcher() {
  yield all([
    takeEvery(ActionTypes.STATS_INIT, initStatsSaga),
    takeEvery(ActionTypes.STATS_LOAD, loadUserStatsSaga),
    takeEvery(ActionTypes.STATS_PEERGROUPS_LOAD, loadPeerGroupSaga),
    takeEvery(ActionTypes.STATS_AGE_GROUP_SAVE, saveAgeGroup),
    takeEvery(ActionTypes.STATS_ADD_QUEUE, addQueueSaga),
    takeEvery(ActionTypes.STATS_QUEUE_SAVE, saveStatsQueueSaga),
  ]);
}
